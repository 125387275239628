.cta-cta {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-turquoise-default);
}
.cta-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.cta-container1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.cta-text1 {
  max-width: 500px;
}
.cta-text2 {
  white-space: pre-wrap;
}
.cta-text3 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.cta-text4 {
  white-space: pre-wrap;
}
.cta-container2 {
  width: 520px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.cta-image {
  top: -100px;
  left: auto;
  right: var(--dl-space-space-doubleunit);
  width: 232px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.cta-image1 {
  top: auto;
  left: var(--dl-space-space-doubleunit);
  right: auto;
  width: 232px;
  bottom: -100px;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .cta-container2 {
    display: none;
  }
}
@media(max-width: 767px) {
  .cta-text {
    font-size: 40px;
  }
}
