.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/ft-bg-hero-1500w.png");
}
.home-container01 {
  top: 0px;
  left: 0px;
  width: 1200px;
  height: 70vh;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: flex-start;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: none;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  width: 100%;
  height: 699px;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  color: #2a2a2f;
  width: 1069px;
  text-align: left;
  line-height: 5rem;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-image {
  flex: 1;
  width: 320px;
  display: none;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  display: none;
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  display: none;
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-clients {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-image1 {
  width: 100px;
  object-fit: cover;
}
.home-image2 {
  width: 100px;
  object-fit: cover;
}
.home-image3 {
  width: 100px;
  object-fit: cover;
}
.home-image4 {
  width: 100px;
  object-fit: cover;
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text08 {
  max-width: 500px;
  text-align: center;
}
.home-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-container03 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container04 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-image5 {
  top: -22px;
  left: -65px;
  right: auto;
  width: 100px;
  bottom: auto;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.home-text13 {
  z-index: 100;
  max-width: 500px;
}
.home-container05 {
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container06 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-container07 {
  width: 350px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container08 {
  width: 300px;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
.home-achievements {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container09 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container10 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container11 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container12 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container13 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-purple-100);
}
.home-image6 {
  width: 360px;
  margin-top: 100px;
  object-fit: cover;
}
.home-container14 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text20 {
  font-size: 40px;
  font-family: Inter;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.home-text21 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container15 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text22 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-image7 {
  width: 100%;
  height: 400px;
  margin-top: var(--dl-space-space-tripleunit);
  object-fit: cover;
}
@media(max-width: 991px) {
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-container02 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-text {
    text-align: center;
  }
  .home-container03 {
    flex-direction: column;
  }
  .home-container05 {
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-container06 {
    align-items: center;
    margin-left: 0px;
  }
  .home-container08 {
    width: 350px;
  }
  .home-achievements {
    flex-direction: column;
  }
  .home-container10 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .home-feature1 {
    flex-direction: column;
  }
  .home-container14 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-container15 {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-text {
    font-size: 50px;
    text-align: center;
  }
  .home-clients {
    justify-content: center;
  }
  .home-image1 {
    margin: var(--dl-space-space-unit);
  }
  .home-image2 {
    margin: var(--dl-space-space-unit);
  }
  .home-image3 {
    margin: var(--dl-space-space-unit);
  }
  .home-image4 {
    margin: var(--dl-space-space-unit);
  }
  .home-text07 {
    font-size: 40px;
  }
  .home-container04 {
    align-items: center;
  }
  .home-image5 {
    top: -2px;
    left: 21px;
    right: auto;
    width: 50px;
    bottom: auto;
  }
  .home-text13 {
    font-size: 40px;
    text-align: center;
  }
  .home-text15 {
    font-size: 40px;
  }
  .home-heading {
    font-size: 40px;
  }
}
@media(max-width: 479px) {
  .home-text07 {
    text-align: center;
  }
  .home-text08 {
    text-align: center;
  }
  .home-container09 {
    align-items: center;
  }
  .home-text15 {
    text-align: center;
  }
  .home-text19 {
    text-align: center;
  }
  .home-container11 {
    flex-direction: column;
  }
  .home-container12 {
    flex-direction: column;
  }
}
